<template>

  <div>
    <b-overlay
      :show="LOADER"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Profile">
        <b-media
          v-if="USER !== null"
          no-body
        >
          <b-media-aside>
            <b-link>
              <b-overlay
                :show="imageOverlay"
                class="d-inline-block"
                rounded
              >

                <b-img
                  v-if="USER.avatar"
                  ref="previewEl"
                  rounded
                  :src="USER.avatar"
                  height="80"
                />
                <b-img
                  v-else
                  rounded
                  src="@/assets/images/pages/user/dummy-user.svg"
                  height="80"
                  class="border p-75"
                  @click="$refs.refInputEl.$el.click()"
                />
              </b-overlay>
            </b-link>
          </b-media-aside>

          <b-media-body>
            <b-button
              variant="primary"
              size="sm"
              class="mr-75"
              :disabled="USER.avatar ? true:false"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageHandler"
            />
            <b-button
              variant="outline-secondary"
              size="sm"
              :disabled="USER.avatar ? false:true"
              @click="removeAvatar()"
            >
              Remove
            </b-button>
            <p class="m-0 mt-1">
              <small>
                Allowed JPG, GIF or PNG.
                Recommended Size: 80px x 80px
              </small>
            </p>
          </b-media-body>
        </b-media>
        <hr>
        <validation-observer
          ref="updateProfileValidator"
        >
          <b-form
            class="mt-2"
            @submit.prevent="onSubmit"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      name="name"
                      placeholder="Enter name"
                    />
                    <span class="text-danger error-msg">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Username"
                  label-for="username"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="form.username"
                      name="username"
                      placeholder="Enter username"
                    />
                    <span class="text-danger error-msg">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                type="submit"
              >
                Update
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const userModule = createNamespacedHelpers('user')
export default {
  data() {
    return {
      form: {
        name: '',
        username: '',
      },
      currentUsername: null,
      imageOverlay: false,
    }
  },
  computed: {
    ...appModule.mapState(['LOADER']),
    ...userModule.mapState(['USER']),
  },
  async mounted() {
    this.getCurrentUser()
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...userModule.mapActions(['ADD_AVATAR', 'REMOVE_AVATAR', 'UPDATE_PROFILE', 'FETCH_AUTH_USER']),

    async getCurrentUser() {
      await this.FETCH_AUTH_USER()
      this.form.name = this.USER.name
      this.form.username = this.USER.username
      this.currentUsername = this.form.username
    },
    async inputImageHandler(e) {
      try {
        this.imageOverlay = true
        this.UPDATE_LOADER(true)
        const formData = new FormData()
        formData.append('avatar', e)
        await this.ADD_AVATAR(formData)
        this.UPDATE_LOADER(false)
        this.imageOverlay = false
      } catch (error) {
        this.UPDATE_LOADER(false)
        this.imageOverlay = false
      }
    },
    async removeAvatar() {
      try {
        this.imageOverlay = true
        this.UPDATE_LOADER(true)
        await this.REMOVE_AVATAR()
        this.UPDATE_LOADER(false)
        this.imageOverlay = false
      } catch (error) {
        this.UPDATE_LOADER(false)
        this.imageOverlay = false
      }
    },
    onSubmit() {
      this.$refs.updateProfileValidator.validate().then(async success => {
        if (success) {
          try {
            this.UPDATE_LOADER(true)
            const tempForm = {
              name: this.form.name,
              username: null,
            }
            if (this.currentUsername !== this.form.username) {
              tempForm.username = this.form.username
            } else {
              delete tempForm.username
            }
            const resp = await this.UPDATE_PROFILE(tempForm)
            if (resp) {
              this.$nextTick(() => {
                this.$refs.updateProfileValidator.reset()
              })
              this.getCurrentUser()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Profile Updated!',
                  icon: 'PlusIcon',
                  variant: 'success',
                  text: 'Profile has been updated successfully!',
                },
              })
            }
            this.UPDATE_LOADER(false)
          } catch (error) {
            this.UPDATE_LOADER(false)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
